import routePath from 'constants/path';
import history from 'utils/history';

const windowsLocalStorage = window.localStorage;

const auth = {

	/** Example **/
	loggedIn() {
		return !!windowsLocalStorage.accessToken;
	},
	token() {
		return windowsLocalStorage.admintoken;
	},
	id() {
		return windowsLocalStorage.id;
	},
	auth() {
		return windowsLocalStorage.auth;
	},
	/** Example **/

	accessToken() {
		return windowsLocalStorage.accessToken;
	},
	refreshToken() {
		return windowsLocalStorage.refreshToken;
	},
	name() {
		// const token = windowsLocalStorage.admintoken;
		// if (token) {
		// 	const payload = jwtDecode(token);
		// 	return payload.name;
		// }
		// return '';
	},
	errorProcessing(status) {
		if (status === 401 || status === '401') {
			windowsLocalStorage.removeItem('admintoken');
			window.location.reload();
			return history.push(routePath.login);
		}
		return 0;
	},
};

export default auth;
