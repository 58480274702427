import { SET_LIST, SET_TARGET } from 'constants/manufacture';

const initialState = {
	list: [],
	target: {
		id: 0,
		user_id: 0,
		order_number: '',
		product_name: '',
		amount: 0,
		spec: '',
		expected: 0,
		final: 0,
		people: 0,
		people_time: 0,
		total_people_time: 0,
		machine_time: 0,
		total_machine_time: 0,
		datetime: null,
		note: '',
	},
};

export function manufacture(state = initialState, action) {
	switch (action.type) {
		case SET_LIST:
			return {
				...state,
				list: action.list,
			};
		case SET_TARGET:
			return {
				...state,
				target: action.list,
			};
		default:
			return state;
	}
}
