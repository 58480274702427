import Loadable from 'react-loadable';

import LoadingDimmer from 'components/Utils/LoadingDimmer';

export const BackendNewsPage = Loadable({
    loader: () => import('components/Pages/Backend/News'),
    loading: LoadingDimmer,
});

export const BackendEditNewsPage = Loadable({
    loader: () => import('components/Pages/Backend/News/Editor'),
    loading: LoadingDimmer,
});
