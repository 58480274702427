import history from '../utils/history';
import { SENDING_REQUEST, LOADING_REQUEST } from '../constants/utils';

export function forwardTo(location) {
	return () => history.push(location);
}

export function sendingRequest(sending) {
	return { type: SENDING_REQUEST, sending };
}

export function loadingRequest(loading) {
	return { type: LOADING_REQUEST, loading };
}
