import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { forwardTo } from 'actions/utils';
import routePath from 'constants/path';
import Tab from './Tab';
import styles from './styles.module.scss';

const SideBar = ({ visible, goto, admin }) => {
	const username = 'Matthew Lee';
	const avatarName = username.includes(' ')
		? username.split(' ')[0].slice(0, 1) + username.split(' ')[1].slice(0, 1)
		: username.split(' ')[0].slice(0, 1);
	const role = 'Admin';
	const [selected, setSelected] = useState('總覽');
	const sideBarClass = visible
		? 'side-bar-wrapper visible'
		: 'side-bar-wrapper disable';

	return (
		<div className={sideBarClass}>
			<Link to={routePath.dashboard}>
				<div className={styles.topBlock}>
					<div className={styles.avatar}>
						<p>{avatarName.toUpperCase()}</p>
					</div>
					<div className={styles.infoWrapper}>
						<p>管理員</p>
						<p>{role}</p>
					</div>
				</div>
			</Link>
			{/*<div className={styles.createBlock}>*/}
			{/*	<div*/}
			{/*		className={styles.createBtn}*/}
			{/*		onClick={() => goto(routePath.manufactureCreate)}*/}
			{/*	>*/}
			{/*		新增製令單*/}
			{/*	</div>*/}
			{/*</div>*/}
			<div className={styles.tabWrapper}>
                <Tab
                    title="首頁維護"
                    link={routePath.backend_home}
                    selected={selected}
                    setSelected={setSelected}
                    authLevel={admin}
                />
				<Tab
					title="最新消息"
					link={routePath.backend_news}
					selected={selected}
					setSelected={setSelected}
					authLevel={admin}
				/>
                <Tab
                    title="活動成果"
                    link={routePath.backend_campaign}
                    selected={selected}
                    setSelected={setSelected}
                    authLevel={admin}
                />
                <Tab
                    title="歷任理監事名單"
                    link={routePath.backend_supervisor}
                    selected={selected}
                    setSelected={setSelected}
                    authLevel={admin}
                />
                <Tab
                    title="會員成員"
                    link={routePath.backend_member}
                    selected={selected}
                    setSelected={setSelected}
                    authLevel={admin}
                />
				{/*<Tab*/}
				{/*	title="開始製令"*/}
				{/*	link={routePath.manufactureCreate}*/}
				{/*	selected={selected}*/}
				{/*	setSelected={setSelected}*/}
				{/*	authLevel={admin}*/}
				{/*/>*/}
				{/*<Tab*/}
				{/*	title="製令管理"*/}
				{/*	link={routePath.manufactureRoot}*/}
				{/*	selected={selected}*/}
				{/*	setSelected={setSelected}*/}
				{/*	authLevel={admin}*/}
				{/*/>*/}
				{/*<Tab*/}
				{/*	title="操作員管理"*/}
				{/*	link={routePath.personnelRoot}*/}
				{/*	selected={selected}*/}
				{/*	setSelected={setSelected}*/}
				{/*	authLevel={admin}*/}
				{/*/>*/}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	goto: (location) => dispatch(forwardTo(location)),
});

export default connect(null, mapDispatchToProps)(SideBar);
