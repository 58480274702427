import { SENDING_REQUEST,	LOADING_REQUEST } from 'constants/utils';

const initialState = {
	currentlySending: false,
	currentlyLoading: false,
};

export function utils(state = initialState, action) {
	switch (action.type) {
		case SENDING_REQUEST:
			return {
				...state,
				currentlySending: action.sending,
			};
		case LOADING_REQUEST:
			return {
				...state,
				currentlyLoading: action.loading,
			};
		default:
			return state;
	}
}
