import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import Routes, {firebaseAuth} from './Routes';
import configureStore from './store';
import 'styles/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

const store = configureStore({});

firebaseAuth.onAuthStateChanged(user => {

    console.log("Auth state changed!, user: " + user);

    ReactDOM.render(
        <Provider store={store}>
            <Routes user={user}/>
        </Provider>,
        document.querySelector('#root'));
});

// ReactDOM.render(
// 	<Provider store={store}>
// 		<Routes />
// 	</Provider>,
// 	document.querySelector('#root'),
// );
