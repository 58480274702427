import axios from 'axios';
import routePath from 'constants/path';
import { SET_AUTH, SET_AUTH_LOG, SET_TARGET, SET_ALL_USER } from 'constants/auth';
import authUtils from 'utils/auth';
import { sendingRequest, forwardTo } from './utils';

const windowsLocalStorage = window.localStorage;
// const API_ENDPOINT = 'http://localhost:8080';
const API_ENDPOINT = 'http://206.189.154.239:8888';
const config = {
	headers: {
		'Content-Type': 'application/json',
		'Content-Security-Policy': 'upgrade-insecure-requests',
	},
};
const configWithToken = {
	headers: {
		'Content-Type': 'application/json',
		// eslint-disable-next-line quote-props
		'Authorization': `Bearer ${windowsLocalStorage.accessToken}`,
		'Content-Security-Policy': 'upgrade-insecure-requests',
	},
};

export function setAuth(userState) {
	return { type: SET_AUTH, user: userState };
}

export function setAuthLog(newState) {
	return { type: SET_AUTH_LOG, log: newState };
}


export function setTargetAdmin(admin) {
	return { type: SET_TARGET, admin };
}

export function setAllUser(list) {
	return { type: SET_ALL_USER, list };
}

export function login(account, password) {
	return async dispatch => {
		if (authUtils.loggedIn()) {
			dispatch(sendingRequest(false));
			dispatch(forwardTo(routePath.dashboard));
			return;
		}

		try {
			const response = await axios.post(`${API_ENDPOINT}/login`,
				{ username: account, password },
				config);

			const { accessToken, refreshToken, id, admin } = response.data;

			windowsLocalStorage.accessToken = accessToken;
			windowsLocalStorage.refreshToken = refreshToken;
			windowsLocalStorage.id = id;
			windowsLocalStorage.admin = admin;
			windowsLocalStorage.username = account;

			dispatch(sendingRequest(false));
			dispatch(setAuth({ id, admin, username: account }));
			// dispatch(forwardTo(routePath.dashboard));
			window.location.href = routePath.dashboard;
		} catch (err) {
			dispatch(sendingRequest(false));
		}
	};
}

export function logout() {
	return async dispatch => {
		try {
			await axios.post(`${API_ENDPOINT}/logout`,
				{ id: windowsLocalStorage.id, refreshToken: windowsLocalStorage.refreshToken },
				config);

			windowsLocalStorage.removeItem('accessToken');
			windowsLocalStorage.removeItem('refreshToken');
			windowsLocalStorage.removeItem('id');
			windowsLocalStorage.removeItem('admin');
			windowsLocalStorage.removeItem('username');
			dispatch(setAuth({ id: 0, admin: false, username: '' }));
			dispatch(forwardTo(routePath.login));
		} catch {
			windowsLocalStorage.removeItem('accessToken');
			windowsLocalStorage.removeItem('refreshToken');
			windowsLocalStorage.removeItem('id');
			windowsLocalStorage.removeItem('admin');
			windowsLocalStorage.removeItem('username');
			dispatch(setAuth({ id: 0, admin: false, username: '' }));
			dispatch(forwardTo(routePath.login));
		}
	};
}

export function fetchLog() {
	return async dispatch => {
		const response = await axios.get(`${API_ENDPOINT}/user`,
			configWithToken);

		dispatch(setAuthLog(response.data.reverse()));
	};
}

export function fetchAdminLog() {
	return async dispatch => {
		const response = await axios.get(`${API_ENDPOINT}/admin`,
			configWithToken);

		dispatch(setAuthLog(response.data.reverse()));
	};
}

export function fetchAllUser() {
	return async dispatch => {
		const response = await axios.get(`${API_ENDPOINT}/admin/users`,
			configWithToken);

		dispatch(setAllUser(response.data));
	};
}

export function register(form) {
	return async dispatch => {
		try {
			await axios.post(`${API_ENDPOINT}/admin/register`,
				form,
				configWithToken);
			dispatch(fetchAllUser());
			dispatch(forwardTo(routePath.personnelRoot));
		} catch (err) {
			console.log(err);
		}
	};
}

export function changePwd(form) {
	return async dispatch => {
		try {
			await axios.patch(`${API_ENDPOINT}/reset_password`,
				form,
				configWithToken);

			dispatch(forwardTo(routePath.personalInfo));
		} catch (err) {
			console.log(err);
		}
	};
}
