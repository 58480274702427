import Loadable from 'react-loadable';

import LoadingDimmer from 'components/Utils/LoadingDimmer';

export const BackendCampaignPage = Loadable({
    loader: () => import('components/Pages/Backend/Campaign'),
    loading: LoadingDimmer,
});

export const BackendEditCampaignPage = Loadable({
    loader: () => import('components/Pages/Backend/Campaign/Editor'),
    loading: LoadingDimmer,
});
