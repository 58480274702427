import Loadable from 'react-loadable';

import LoadingDimmer from 'components/Utils/LoadingDimmer';

export const ManufacturePage = Loadable({
	loader: () => import('components/Pages/Backend/Manufacture'),
	loading: LoadingDimmer,
});

export const ManufactureCreatePage = Loadable({
	loader: () => import('components/Pages/Backend/Manufacture/Create'),
	loading: LoadingDimmer,
});
