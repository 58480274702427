import { useState, useEffect } from 'react';

export const useMedia = () => {
	const phoneMedia = window.matchMedia('(max-width: 600px)');
	const tabletMedia = window.matchMedia('(max-width: 960px) and (min-width: 601px)');
	const desktopMedia = window.matchMedia('(max-width: 1280px) and (min-width: 961px)');

	let defaultMedia = 'desktop';

	if (phoneMedia.matches) {
		defaultMedia = 'phone';
	}

	if (tabletMedia.matches) {
		defaultMedia = 'tablet';
	}

	const [media, setMedia] = useState(defaultMedia);

	useEffect(() => {
		const handleMediaChange = mediaName => mediaHandler => {
			if (mediaHandler.matches && mediaName !== media) {
				setMedia(mediaName);
			}
		};

		const phoneHandler = handleMediaChange('phone');
		const tabletHandler = handleMediaChange('tablet');
		const desktopHandler = handleMediaChange('desktop');

		phoneMedia.addListener(phoneHandler);
		tabletMedia.addListener(tabletHandler);
		desktopMedia.addListener(desktopHandler);

		return () => {
			phoneMedia.removeListener(phoneHandler);
			tabletMedia.removeListener(tabletHandler);
			desktopMedia.removeListener(desktopHandler);
		};
	}, [media, phoneMedia, tabletMedia, desktopMedia]);

	return media;
};
