import Loadable from 'react-loadable';

import LoadingDimmer from 'components/Utils/LoadingDimmer';

export const BackendSupervisorPage = Loadable({
    loader: () => import('components/Pages/Backend/Supervisor'),
    loading: LoadingDimmer,
});

export const BackendEditSupervisorPage = Loadable({
    loader: () => import('components/Pages/Backend/Supervisor/Editor'),
    loading: LoadingDimmer,
});
