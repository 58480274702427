import React, { Suspense, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { forwardTo } from '../actions/utils';
import { setAuth } from '../actions/auth';
import routePath from '../constants/path';
import { useMedia } from '../utils/media';

// Front End Layout
import TopBar from "./Navbar/TopBar";
import BottomBar from "./Navbar/BottomBar";

// Backend Layout
import BackendTopBar from 'components/Navbar/Backend/TopBar';
import BackendSideBar from 'components/Navbar/Backend/SideBar';

const windowsLocalStorage = window.localStorage;

const App = ({
	children,
	location: { pathname },
	goto,
	admin,
	setAuthStat,
	username,
}) => {
	const media = useMedia();
	const [visible, setVisible] = useState(true);
	const [mobileVisible, setMobileVisible] = useState(false);

	useState(() => {
		setAuthStat({
			id: windowsLocalStorage.id,
			admin: windowsLocalStorage.admin && JSON.parse(windowsLocalStorage.admin),
			username: windowsLocalStorage.username,
		});
	}, []);

	// useEffect(() => {
	// 	if (!windowsLocalStorage.accessToken) {
	// 		goto(routePath.login);
	// 	}
	// }, [pathname]);

	useEffect(() => {
		if (media === 'phone') {
			setVisible(false);
			setMobileVisible(false);
		} else {
			setMobileVisible(true);
		}
	}, [media, pathname]);

	if(pathname.startsWith('/admin')) {
	    return (
	        <Suspense fallback={<div>Loading...</div>}>
                <BackendTopBar
                    visible={visible}
                    setVisible={setVisible}
                    mobileVisible={mobileVisible}
                    setMobileVisible={setMobileVisible}
                    login={pathname === routePath.login}
                    admin={admin}
                />
                {pathname === routePath.login ? (
                    <div className="wrapper">{children}</div>
                ) : (
                    <div className="wrapper">
                        <BackendSideBar
                            visible={media === 'phone' ? mobileVisible : visible}
                            admin={admin}
                            username={username}
                        />
                        <div className="content">{children}</div>
                    </div>
                )}
            </Suspense>
        )
    }
	return (
        <Suspense fallback={<div>Loading...</div>}>
            <TopBar/>
            {children}
            <BottomBar/>
        </Suspense>
    );
};

const mapStateToProps = ({ auth: { admin, username } }) => ({
	admin,
	username,
});

export default withRouter(
	connect(mapStateToProps, { goto: forwardTo, setAuthStat: setAuth })(App)
);

// export default withRouter(App);
