import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

import styles from './styles.module.scss';

const Tab = ({ title, link, selected, setSelected, authLevel }) => {
	// eslint-disable-next-line no-nested-ternary
	const tabSelected = selected !== title
		? null
		: authLevel ? styles.activeLinkOrange : styles.activeLink;
	const linkStyle = authLevel ? styles.linkOrange : styles.link;

	switch (title) {
		case '最新消息':
		case '會員成員':
        case '活動成果':
        case '歷任理監事名單':
        case '首頁維護':
            return (
				<Link
					className={classnames(linkStyle, tabSelected)}
					to={link}
					onClick={() => setSelected(title)}
				>
					{selected === title
						? <DashboardIcon className={styles.icon} />
						: <DashboardOutlinedIcon className={styles.icon} />}
					<p className={styles.name}>{title}</p>
				</Link>
			);
		case '製令管理':
		    // 如果需要更換其他 icon
			return (
				<Link
					className={classnames(linkStyle, tabSelected)}
					to={link}
					onClick={() => setSelected(title)}
				>
					{selected === title
						? <AssignmentIcon className={styles.icon} />
						: <AssignmentOutlinedIcon className={styles.icon} />}
					<p className={styles.name}>{title}</p>
				</Link>
			);
		case '操作員管理':
			return (
				<Link
					className={classnames(linkStyle, tabSelected)}
					to={link}
					onClick={() => setSelected(title)}
				>
					{selected === title
						? <PeopleAltIcon className={styles.icon} />
						: <PeopleAltOutlinedIcon className={styles.icon} />}
					<p className={styles.name}>{title}</p>
				</Link>
			);
		default:
			return null;
	}
};

export default Tab;
