import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import logger from 'redux-logger';

import reducer from './reducers';

const middlewares = [reduxThunk, logger];

// if (process.env.NODE_ENV !== 'production') {
// 	const { createLogger } = require('redux-logger'); // eslint-disable-line global-require
// 	middlewares.push(
// 		createLogger(),
// 	);
// }

const createStoreWithMiddleware = compose(applyMiddleware(...middlewares))(createStore);

export default function configureStore() {
	const store = createStoreWithMiddleware(reducer);

	return store;
}
