import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link} from 'react-router-dom';
import routePath from '../../../constants/path';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import styles from './styles.module.scss'

import icon_fb from '../../../assets/social-icons/facebook.svg';
import icon_yt from '../../../assets/social-icons/youtube.svg';

const BottomBar = () => {
    return (
        <div className="w-100 bg-light">
            <Container className={styles.container}>
                <div className={styles.link_group}>
                    <div className={styles.group_title}>2024 3D列印協會</div>
                    <div className={styles.link_group_bar}>
                        <Link to={routePath.home} className={styles.link}>
                            回到首頁
                        </Link>
                        <Link to={routePath.news} className={styles.link}>
                            最新消息
                        </Link>
                        <Link to={routePath.about} className={styles.link}>
                            關於協會
                        </Link>
                    </div>

                    <div className={styles.link_group_bar}>
                        <Link to={routePath.campaign} className={styles.link}>
                            活動成果
                        </Link>
                        <Link to={routePath.member} className={styles.link}>
                            會員成員
                        </Link>
                        <Link to={routePath.contact} className={styles.link}>
                            聯繫我們
                        </Link>
                    </div>

                </div>

                <div className={styles.contact_group}>
                    <div className={styles.social_group}>
                        <a
                            href="https://www.facebook.com/%E4%B8%89%E7%B6%AD%E5%88%97%E5%8D%B0%E5%8D%94%E6%9C%83-119558266549390/"
                            target="_blank"
                            className={styles.social_icon}
                            style={{
                                width: '3em',
                                height: '2.5em',
                                background: `url(${icon_fb})`,
                                backgroundSize: '2em 3em',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'left'
                            }}
                        />
                        <a
                            href="https://www.youtube.com/channel/UCWG7yJG4_OzhbBqNySAee5A"
                            target="_blank"
                            className={styles.social_icon}
                            style={{
                                width: '4em',
                                height: '2.5em',
                                background: `url(${icon_yt})`,
                                backgroundSize: '2.5em',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'left'
                            }}
                        />
                    </div>
                    <div>電話：0922968238</div>
                    <div>辦公室電話/分機：03-6210505#8016</div>
                    <div className="d-inline">網站：<span className={styles.link}>http://3dp.org.tw/</span></div>
                </div>

                <div className={styles.copyright_group}>
                    <div>Copyright © 2024</div>
                    <div>Additive Manufacturing Association of Taiwan(AMAT)</div>
                </div>
            </Container>
        </div>
    )
};

export default BottomBar;