import Loadable from 'react-loadable';

import LoadingDimmer from 'components/Utils/LoadingDimmer';

export const BackendMemberPage = Loadable({
    loader: () => import('components/Pages/Backend/Member'),
    loading: LoadingDimmer,
});

export const BackendEditMemberPage = Loadable({
    loader: () => import('components/Pages/Backend/Member/Editor'),
    loading: LoadingDimmer,
});
