import { combineReducers } from 'redux';

import { auth } from './auth';
import { utils } from './utils';
import { manufacture } from './manufacture';

export default combineReducers({
	auth,
	utils,
	manufacture,
});
