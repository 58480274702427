import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from 'react-router-dom';
import routePath from '../../../constants/path';
import img_logo from '../../../assets/frontend_logo.png';

import logo from '../../../assets/logo-3d-print.png';

import styles from './styles.module.scss';

const TopBar = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand className={styles.navbarBrand}>
                <Link to={routePath.home} className="navbar-brand">
                    <img
                        alt="LOGO"
                        src={logo}
                        width="100%"
                        height="100%"
                        className="d-inline-block align-top"
                    />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className={styles.navbarCollapse}>
                <Nav>
                    <Nav.Link>
                        <Link to={routePath.home} className="navbar-nav nav-link">
                            首頁
                        </Link>
                    </Nav.Link>

                    <Nav.Link>
                        <Link to={routePath.news} className="navbar-nav nav-link">
                            最新消息
                        </Link>
                    </Nav.Link>

                    <Nav.Link>
                        <Link to={routePath.about} className="navbar-nav nav-link">
                            關於協會
                        </Link>
                    </Nav.Link>

                    <Nav.Link>
                        <Link to={routePath.campaign} className="navbar-nav nav-link">
                            活動成果
                        </Link>
                    </Nav.Link>

                    <Nav.Link>
                        <Link to={routePath.member} className="navbar-nav nav-link">
                            會員成員
                        </Link>
                    </Nav.Link>

                    <Nav.Link>
                        <Link to={routePath.contact} className="navbar-nav nav-link">
                            聯繫我們
                        </Link>
                    </Nav.Link>

                    {/** Drop down **/}
                    {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
                    {/*    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                    {/*    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                    {/*    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                    {/*    <NavDropdown.Divider />*/}
                    {/*    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
                    {/*</NavDropdown>*/}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default TopBar;
