import Loadable from 'react-loadable';

import LoadingDimmer from 'components/Utils/LoadingDimmer';

export const BackendHomePage = Loadable({
    loader: () => import('components/Pages/Backend/Home'),
    loading: LoadingDimmer,
});

export const BackendEditHomePage = Loadable({
    loader: () => import('components/Pages/Backend/Home/Editor'),
    loading: LoadingDimmer,
});
