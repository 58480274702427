export default {
	home: '/',
	news: '/news',
	about: '/about',
    constitution: '/association-constitution',
    previousSupervisors: '/previous-supervisors',
	campaign: '/campaign',
	member: '/member',
    memberJoin: '/member/join',
	contact: '/contact',

	dashboard: '/admin',
	login: '/admin/login',
    backend_home: '/admin/home',
    backend_home_edit: '/admin/home/edit',
    backend_home_create: '/admin/home/create',
    backend_news: '/admin/news',
    backend_news_edit: '/admin/news/edit',
    backend_news_create: '/admin/news/create',
	backend_about: '/admin/about',
	backend_constitution: '/admin/constitution',
	backend_campaign: '/admin/campaign',
    backend_campaign_edit: '/admin/campaign/edit',
    backend_campaign_create: '/admin/campaign/create',
    backend_supervisor: '/admin/supervisor',
    backend_supervisor_edit: '/admin/supervisor/edit',
    backend_supervisor_create: '/admin/supervisor/create',
	backend_member: '/admin/member',
	backend_member_edit: '/admin/member/edit',
	backend_member_create: '/admin/member/create',
};
