import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routePath from "../constants/path";

const PrivateRoute = ({ user, component: Comp, ...rest }) => {
    return (
        <Route
            {...rest}
            component={props =>
                user ? <Comp {...props} user={user} /> : <Redirect to={routePath.login}/>
            }
        />
    );
};

export default PrivateRoute;
