import React from 'react';
import {connect} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import {logout} from 'actions/auth';

import styles from './styles.module.scss';
import {firebaseAuth} from "../../../../Routes";
import routePath from "../../../../constants/path";

const TopBar = ({visible, setVisible, mobileVisible, setMobileVisible, login, exit,}) => {

    const history = useHistory();

    // allow read, write: if
    //     request.time < timestamp.date(2020, 10, 2);

    const signOut = async () => {
        await firebaseAuth.signOut();
        history.replace(routePath.login);
    };

    return (
        <div className="top-bar-wrapper">
            {!login
            && (
                <div
                    className={styles.hamburgerMenu}
                    onClick={() => {
                        setVisible(!visible);
                        setMobileVisible(!mobileVisible);
                    }}
                >
                    <span/>
                    <span/>
                    <span/>
                </div>
            )}
            <Link to="/" className={styles.title}><p>3D 列印協會</p></Link>
            {!login
            && (
                <div onClick={signOut} className={styles.signOutBtn}>
                    <ExitToAppIcon className={styles.signOut}/>
                    <p>登出</p>
                </div>
            )}
        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    exit: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(TopBar);
