import { SET_AUTH,	SET_AUTH_LOG, SET_TARGET, SET_ALL_USER } from 'constants/auth';

const initialState = {
	id: 0,
	admin: false,
	log: [],
	username: '',
	target: {
		id: '',
		username: '',
		admin: false,
		at: '',
		login: '',
	},
	allUser: [],
};

export function auth(state = initialState, action) {
	switch (action.type) {
		case SET_AUTH:
			return {
				...state,
				id: action.user.id,
				admin: action.user.admin,
				username: action.user.username,
			};
		case SET_AUTH_LOG:
			return {
				...state,
				log: action.log,
			};
		case SET_TARGET:
			return {
				...state,
				target: action.admin,
			};
		case SET_ALL_USER:
			return {
				...state,
				allUser: action.list,
			};
		default:
			return state;
	}
}
