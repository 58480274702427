import React, {useEffect} from 'react';
import {Router, Route, withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';

import history from './utils/history';
import routePath from './constants/path';

import App from './components';

// Front End
import {CampaignPage} from './routes/campaign';
import {CampaignDetailPage} from './routes/campaignDetail'
import {ContactPage} from './routes/contact';
import {HomePage} from './routes/home';
import {MemberPage} from './routes/member';
import {MemberJoinPage} from './routes/memberJoin';
import {NewsPage} from './routes/news';
import {AboutPage} from './routes/about';
import {ConstitutionPage} from './routes/constitution';
import {PreviousSupervisorsPage} from './routes/prevousSupervisors';
import {SingleNewsPage} from './routes/singleNews';

// Backend
import {LoginPage} from './routes/Backend/auth';
import {DashboardPage} from './routes/Backend/dashboard';
import {BackendNewsPage, BackendEditNewsPage} from './routes/Backend/news';

import {
    ManufacturePage,
    ManufactureCreatePage,
} from './routes/Backend/manufacture';
import {PersonnelPage} from './routes/Backend/personnel';
import {PersonalInfoPage} from './routes/Backend/personalInfo';

import * as firebase from 'firebase';
import {BackendCampaignPage, BackendEditCampaignPage} from "./routes/Backend/campaign";
import PrivateRoute from "./authRoutes/privateRoute";
import {BackendEditSupervisorPage, BackendSupervisorPage} from "./routes/Backend/supervisor";
import {BackendEditMemberPage, BackendMemberPage} from "./routes/Backend/member";
import {BackendEditHomePage, BackendHomePage} from "./routes/Backend/home";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "d-print-association.firebaseapp.com",
    databaseURL: "https://d-print-association.firebaseio.com",
    projectId: "d-print-association",
    storageBucket: "d-print-association.appspot.com",
    messagingSenderId: "1052401650750",
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: "G-GNY4SHSE4X"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();
export const firebaseAuth = firebase.auth();
export const newsRef = db.collection("news");
export const campaignRef = db.collection("campaign");
export const supervisorRef = db.collection("supervisor");
export const statisticsRef = db.collection("statistics");
export const memberRef = db.collection("member");
export const homeRef = db.collection("home");

const storage = firebase.storage();
export const imagesRef = storage.ref("images");

// const analytics = firebase.analytics();
// analytics.logEvent("page_view")

const Routes = (props) => {

    // useEffect(() => {
    //     ReactGA.initialize('UA-178473233-1');
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // }, []);

    return (
        <Router history={history}>
            <App>
                {/*  Frontend  */}
                <Route {...props} exact path={routePath.home} component={HomePage}/>
                <Route {...props} exact path={routePath.news} component={NewsPage}/>
                <Route {...props} path={`${routePath.news}/:newsId`} component={SingleNewsPage}/>
                <Route {...props} exact path={routePath.campaign} component={CampaignPage}/>
                <Route {...props} path={`${routePath.campaign}/:campaignId`} component={CampaignDetailPage}/>
                <Route {...props} exact path={routePath.member} component={MemberPage}/>
                <Route {...props} exact path={routePath.memberJoin} component={MemberJoinPage}/>
                <Route {...props} exact path={routePath.about} component={AboutPage}/>
                <Route {...props} exact path={routePath.constitution} component={ConstitutionPage}/>
                <Route {...props} exact path={`${routePath.previousSupervisors}/:sessionId`}
                       component={PreviousSupervisorsPage}/>
                <Route {...props} exact path={routePath.contact} component={ContactPage}/>

                <Route {...props} exact path={routePath.login} component={LoginPage}/>

                {/*  Backend  */}
                <PrivateRoute {...props} exact path={routePath.dashboard} component={DashboardPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_news} component={BackendNewsPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_news_create} component={BackendEditNewsPage}/>
                <PrivateRoute {...props} exact path={`${routePath.backend_news_edit}/:newsId`}
                              component={BackendEditNewsPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_campaign} component={BackendCampaignPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_campaign_create}
                              component={BackendEditCampaignPage}/>
                <PrivateRoute {...props} exact path={`${routePath.backend_campaign_edit}/:campaignId`}
                              component={BackendEditCampaignPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_supervisor} component={BackendSupervisorPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_supervisor_create}
                              component={BackendEditSupervisorPage}/>
                <PrivateRoute {...props} exact path={`${routePath.backend_supervisor_edit}/:sessionId`}
                              component={BackendEditSupervisorPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_member} component={BackendMemberPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_member_create}
                              component={BackendEditMemberPage}/>
                <PrivateRoute {...props} exact path={`${routePath.backend_member_edit}/:memberId`}
                              component={BackendEditMemberPage}/>
                <PrivateRoute {...props} exact path={routePath.backend_home} component={BackendHomePage}/>
                <PrivateRoute {...props} exact path={routePath.backend_home_create}
                              component={BackendEditHomePage}/>
                <PrivateRoute {...props} exact path={`${routePath.backend_home_edit}/:heroId`}
                              component={BackendEditHomePage}/>

                {/*<Route*/}
                {/*	exact*/}
                {/*	path={routePath.manufactureRoot}*/}
                {/*	component={withRouter(ManufacturePage)}*/}
                {/*/>*/}
                {/*<Route*/}
                {/*	exact*/}
                {/*	path={routePath.manufactureCreate}*/}
                {/*	component={ManufactureCreatePage}*/}
                {/*/>*/}
                {/*<Route exact path={routePath.personnelRoot} component={PersonnelPage} />*/}
                {/*<Route exact path={routePath.personalInfo} component={PersonalInfoPage} />*/}

                {/* 404 */}
            </App>
        </Router>
    )
};

export default Routes;
