import Loadable from 'react-loadable';

import LoadingDimmer from 'components/Utils/LoadingDimmer';

export const LoginPage = Loadable({
	loader: () => import('components/Auth/LoginPage'),
	loading: LoadingDimmer,
});

// export const ResetPage = Loadable({
// 	loader: () => import('components/Auth/ResetPage'),
// 	loading: LoadingDimmer,
// });
